<template>
    <Navbar />
    <div class="flex-1">
        <slot />
    </div>
    <Footer />
</template>

<script setup lang="ts">
useHead({
    htmlAttrs: {
        class: 'pt-[80px] lg:pt-[96px]'
    }
})
</script>